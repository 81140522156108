import React from 'react'
import {Container} from '../container/GlobalContainer'
import styled from '@emotion/styled'
import {FormattedMessage, useIntl} from 'gatsby-plugin-intl'
import FancyVisionBlock from '../../images/fancyblockvision.inline.svg'
import {device} from '../../devices'

export const OurVision = () => {
  const intl = useIntl()
  return (
    <Container>
      <ContainerTitle data-sal="slide-up" data-sal-duration="500" data-sal-easing="ease">
        <TitleBlue>
          <h2>
            <FormattedMessage id="our_vision.title"/>
          </h2>
        </TitleBlue>
        <TitleBlocBorder/>
      </ContainerTitle>
      <ContainerBloc>
        <ContainerRectangleFancy>
          <FancyVisionBlock/>
        </ContainerRectangleFancy>
        <FancyBlock>
          <p dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'our_vision.fancyBlock'})}}/>
        </FancyBlock>
        <WhiteBloc>
          <p dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'our_vision.whiteBlock'})}}/>
        </WhiteBloc>
      </ContainerBloc>
    </Container>
  )
}

const ContainerBloc = styled.div`
  display: flex;
  margin-top: -15vw;
  
  @media ${device.mobileL} {
      flex-direction: column;
  }
`

const ContainerTitle = styled.div`
display: flex;
width: 100%;
position: relative;
min-height: 200px;
margin-top: 76px;

@media ${device.mobileL} {
    animation: inherit;
    opacity:1;
    min-height: 24vw;
    margin-top: 0;
}
`

const TitleBlocBorder = styled.div`
      position: absolute;
      top: 0;
      z-index: 1;
      right: 0;
      margin-right: -10px;
      border: double 2px transparent;
      border-radius: 30px 0 0 30px;
      background-image: linear-gradient(white, white),
                    linear-gradient(.75turn, #0000ff 0%, #0ccdfe 50%, #66ffe5 70%);
      background-origin: border-box;
      background-clip: content-box, border-box;
      width: 91%;
      height: 89px;
      transform: rotate(1deg);
   @media ${device.mobileL} {
    height: 61px;
    border-radius: 15px 0 0 15px;
  }
`

const TitleBlue = styled.div`
  width: 90%;
  top: 0;
  right: 0;
  margin-right: -10px;
  position: absolute;
  height: 89px;
  transform: rotate(-1deg);
  border-radius: 30px 0 0 30px;
  background-image: linear-gradient(.75turn, #0000ff 0%, #0ccdfe 70%, #66ffe5 90%);
  z-index: 2;

  h2 {
      padding-left:17.090vw;
      transform: rotate(-1deg);
      font-size: 3.4vw;
      font-weight: bold;
      font-stretch: normal;
      line-height: 89px;
      font-style: normal;
      letter-spacing: -0.05px;
      color: #fff;
  }
  
  @media ${device.mobileL} {
    height: 61px;
    border-radius: 15px 0 0 15px;
    
    h2 {
      padding-left: 8.09vw;
      font-size: 200%;
      line-height: 191%;
    }
  }
`

const WhiteBloc = styled.div`
  width: 46%;
  height: auto;
  transform: rotate(-1deg);
  border-radius: 60px;
  box-shadow: -13px 12px 22px 0px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  z-index: 100;

  p {
    background: linear-gradient(91deg, #0000ff -1%, #0ccdfe 51%, #66ffe5 128%);
    font-size: 2.2vw;
    line-height: 2.3vw;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-stretch: normal;
    font-style: normal;
    margin: 32% 10% 26% 18%;
    b {
      font-weight: bold;
    }
  }
  
  @media ${device.mobileL} {
    width: 70%;
    margin-top: -12vw;
    p {
      font-size: 120%;
      line-height: initial;
      margin: 24% 10% 21% 18%;
    }
  }
`

const FancyBlock = styled.div`
  width: 38%;
  align-self: flex-end;
  transform: rotate(-1deg);
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 45px;
  margin-left: -65px;

  &:after {
    border-radius: 60px;
    content: "";
    background-image: linear-gradient(to left bottom, #0000ff -1%, #0ccdfe 51%, #66ffe5 128%);
    opacity: 0.07;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }

  p {
    font-size: 1.8vw;
    line-height: 2.3vw;
    color: #000;
    font-stretch: normal;
    font-style: normal;
    opacity: inherit;
    margin: 28% 17% 22% 21%;
    b {
      font-weight: bold;
    }
  }
  
  @media ${device.mobileL} {
    margin-left: 0;
    margin-right: 24vw;
    width: 79%;
    p {
      font-size: 120%;
      line-height: initial;
    }
  }
`

const ContainerRectangleFancy = styled.div`
    align-self: flex-start;
    position: relative;
    margin-left: -19vw !important;
    padding-top: 6vw;
    margin-right: 7vw;
    width: auto;
    height: 44vw;
    
    svg {
      width: auto;
      height:100%;
    }
    
    @media ${device.mobileL} {
      display: none;
    }
`
