import React from 'react'
import {Link} from 'gatsby'
import {FormattedMessage} from 'gatsby-plugin-intl'
import {GlobalContainer} from '../container/GlobalContainer'
import styled from '@emotion/styled'
import {TitleGradientHome} from '../title'
import {ButtonGradient} from '../buttons/button'
import {Text} from '../text'
import Illus from '../../images/Illu1_home.inline.svg'
import {device} from '../../devices'

export const PaasBlockHome = () => {
  return (
    <ContainerPassBlock>
      <GlobalContainer>
        <Container>
          <ContainerLeft>
            <TitleGradientHome>
              <FormattedMessage id="process_as_a_service"/>
            </TitleGradientHome>
            <Text>
              <FormattedMessage id="content_paas_home"/>
            </Text>
            <Link to="/paas">
              <ButtonGradient>
              <Text><FormattedMessage id="content_pass_home_button"/></Text>
              </ButtonGradient>
            </Link>
          </ContainerLeft>
        </Container>
      </GlobalContainer>
      <BlocImg>
        <Illus/>
      </BlocImg>
    </ContainerPassBlock>
  )
}

const BlocImg = styled.div`
  height: auto; 
  width: 50%;
  position: absolute;
  right: 0; 
  top: 0;
  
  svg {
    width: 62vw;
    height: auto;
  }
  
  @media ${device.mobileL} {
    width: 100%;
    top: 367px;
    svg {
      width: 100%;
    }
  }
`
const ContainerPassBlock = styled.div`
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   position: relative;
   height: 55vw;
   @media ${device.mobileL} {
    height: 900px;
    justify-content: flex-start;
  }
`

const ContainerLeft = styled.div`
display: flex;
flex-direction: column;

h2 {
  width: 100%;
  text-align:center;
}

p {
  width: 30vw;
  margin: 1.953vw 0;
}

button {
  p {width:auto;margin:0;}
}

@media ${device.mobileL} {
  width: 100%;
  align-items: flex-start;
  text-align: left;
  
  p {
    width: 100%;
    margin: 20px 0;
    text-align:center;
  }
  
  a {
    margin-top: 106vw;
    width: 100%;
    text-align: center;
  }
}
`

const Container = styled.div`
  height: auto;
  display: flex;
  
  @media ${device.mobileL} {
    height: 100%;
    align-items: center;
  }
`
