import React from 'react'
import {FormattedMessage, useIntl} from 'gatsby-plugin-intl'
import Img from 'gatsby-image'
import {Container, GlobalContainer} from '../container/GlobalContainer'
import styled from '@emotion/styled'
import {TitleGradientHome} from '../title'
import {Text} from '../text'
import {graphql, useStaticQuery} from 'gatsby'
import {device} from '../../devices'

export const Team = () => {
  const intl = useIntl()
  return (
    <ContainerTeam>
      <GlobalContainer>
        <TitleGradientHome>
          <FormattedMessage id="team.title"/>
        </TitleGradientHome>
        <TextContainer>
          <Text dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'team.content'})}}/>
        </TextContainer>
        <ContainerImg>
          <Image />
        </ContainerImg>
      </GlobalContainer>
    </ContainerTeam>
  )
}

const Image = ({style}) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "team.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img style={{...style}} fluid={data.file.childImageSharp.fluid}/>
}

const ContainerImg = styled.div`
    position: absolute;
    right: -7vw;
    top: -14vw;
    width: 59vw;
    
    @media ${device.mobileL} {
      position: absolute;
      right: -7vw;
      width: 84vw;
      top: 478px;
    }
`
const ContainerTeam = styled(Container)`
  height: auto;
  min-height: 75vw;
  justify-content: center;
  align-items: center;
  @media ${device.mobileL} {
    min-height: 1000px;
    justify-content: flex-start;
    padding: 20vw 0;
  }
`

const TextContainer = styled.div`
  max-width: 35vw;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.02px;
  color: #000000;
  margin-top: 3vw;
  
  b {
    font-weight: bold;
  }
  
  @media ${device.mobileL} {
    max-width: 100%;
    margin-top: 40px;
    text-align: center;
  }
`
