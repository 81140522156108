import React from 'react'
import {useIntl} from 'gatsby-plugin-intl'
import Layout from '../components/layout'
import SEO from '../components/seo'
import {HeroStandard} from '../components/hero/hero.standard'
import {PaasBlockHome} from '../components/home/paas-block-home'
import {WhyUseOurPass} from '../components/home/why-use-our-paas'
import {OurVision} from '../components/home/our-vision'
import {Team} from '../components/home/team'
import {ContactForm} from '../components/home/contact'


const IndexPage = () => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({id: 'title'})}
      />
      <HeroStandard />
      <PaasBlockHome/>
      <WhyUseOurPass/>
      <OurVision />
      <Team/>
      <ContactForm/>
    </Layout>
  )
}
export default IndexPage
