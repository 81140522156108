import {Container, GlobalContainer} from '../container/GlobalContainer'
import React from 'react'
import {TitleGradientHome} from '../title'
import {FormattedMessage} from 'gatsby-plugin-intl'
import styled from '@emotion/styled'
import {ButtonGradient} from '../buttons/button'
import {device} from '../../devices'

export const ContactForm = () => {
  return (
    <Container id="contact-us">
      <GlobalContainer>
        <CenterText>
          <TitleGradientHome>
            <FormattedMessage id="contact.title"/>
          </TitleGradientHome>
          <FormContainer name="contact" method="POST" data-netlify="true" action="#" netlify-honeypot="bot-field">
            <HalfContainer style={{alignItems: 'flex-end'}}>
              <label>
                <FormattedMessage id="contact.input_name"/>
              </label>
              <Input placeholder="_" type="text" name="name" required={true}/>
              <label>
                <FormattedMessage id="contact.input_email"/>
              </label>
              <Input placeholder="_" type="text" name="email" required={true}/>
            </HalfContainer>
            <HalfContainer>
              <label>
                <FormattedMessage id="contact.input_message"/>
              </label>
              <Textarea placeholder="_" type="text" name="message" required={true}/>
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <Submit type="submit"><FormattedMessage id="contact.submit_button"/></Submit>
            </HalfContainer>
          </FormContainer>
        </CenterText>
      </GlobalContainer>
    </Container>
  )
}

const Submit = styled(ButtonGradient)`
  margin: 0 0 0 0;
  width: 17vw;
`

const FormContainer = styled.form`
display: flex;
width: 100%;
justify-content: center;
align-items: flex-start;
margin-top: 58px;
padding-bottom: 80px;

@media ${device.mobileL} {
  flex-direction: column;
  
  label {
    align-items: flex-start !important;
  }
}
`

const HalfContainer = styled.div`
  display:flex;
  flex-direction: column;
  position: relative;
  width: auto;
  
  &:nth-of-type(2) {
     margin-left: 25px;
  }
  
  button {
   align-self: flex-end;
  }
  
  label {
    width: 24.609vw;
    font-size: 1.4vw;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.002vw;
    color: #000000;
    margin-bottom: 1.2vw;
  }
  
  @media ${device.mobileL} {
    align-items: center !important;
    width: 100%;
    
    label {
      width: 100%;
      font-size: 120%;
    }
    
    button {
      align-self: center;
      margin-top: 5vw;
    }
    
    &:nth-of-type(2) {
     margin-left: 0;
    }
  }
`

const Input = styled.input`
  width: 24.609vw;
  height: 4.492vw;
  border-radius: 0.391vw;
  box-shadow: 0.19vw 0.19vw 1.367vw 0 rgba(0, 0, 0, 0.26);
  background-color: #fff;
  border: 0.19vw solid transparent;
  margin-bottom: 1.66vw;
  padding-left: 10px;
  
  ::placeholder {
    font-size: 2.246vw;
    font-weight: 500;
    color: ${({theme}) => theme.primary};
  }
  
  &:focus {
    border: 0.19vw solid ${({theme}) => theme.primary};
  }
  
  @media ${device.mobileL} {
    width: 100%;
    height: 46px;
    margin-bottom: 20px;
    border-bottom: 2px solid ${({theme}) => theme.primary};
  }
`

const Textarea = styled.textarea`
  width: 32.4vw;
  max-height: 14.1vw;
  height: 14.1vw;
  border-radius: 4px;
  box-shadow: 0.19vw 0.19vw 1.367vw 0 rgba(0, 0, 0, 0.26);
  background-color: #fff;
  border: 0.19vw solid transparent;
  margin-bottom: 1.66vw;
  padding-left: 10px;
  resize: none;
  
  ::placeholder {
    font-size: 2.246vw;
    font-weight: 500;
    color: ${({theme}) => theme.primary};
  }
  
  &:focus {
    border: 2px solid ${({theme}) => theme.primary};
  }
  
  @media ${device.mobileL} {
    width: 100%;
    max-height: 147px;
    height: 147px;
    border-bottom: 2px solid ${({theme}) => theme.primary};
  }
`

const CenterText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
