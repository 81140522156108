import React from 'react'
import {Container, GlobalContainer} from '../container/GlobalContainer'
import styled from '@emotion/styled'
import {TitleGradientHome} from '../title'
import {FormattedMessage} from 'gatsby-plugin-intl'
import {device} from '../../devices'
import {Text} from '../text'

export const WhyUseOurPass = () => {
  return (
    <ContainerSection>
      <ContainerTitle>
        <ImgTitle/>
        <TitleGradientHome>
          <FormattedMessage id="why_use_our_pass.title"/>
        </TitleGradientHome>
      </ContainerTitle>
      <GlobalContainer>
        <ContainerBloc>
          <div data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease">
            <h2>
              <FormattedMessage id="why_use_our_pass.scale_fast.title"/>
            </h2>
            <Text>
              <FormattedMessage id="why_use_our_pass.scale_fast.content"/>
            </Text>
          </div>
          <div data-sal="slide-up" data-sal-duration="900" data-sal-easing="ease">
            <h2>
              <FormattedMessage id="why_use_our_pass.save_time.title"/>
            </h2>
            <Text>
              <FormattedMessage id="why_use_our_pass.save_time.content"/>
            </Text>
          </div>
          <div data-sal="slide-up" data-sal-duration="500" data-sal-easing="ease">
            <h2>
              <FormattedMessage id="why_use_our_pass.save_money.title"/>
            </h2>
            <Text>
              <FormattedMessage id="why_use_our_pass.save_money.content"/>
            </Text>
          </div>
        </ContainerBloc>
      </GlobalContainer>
    </ContainerSection>
  )
}

const ContainerSection = styled(Container)`
padding-bottom: 80px;

@media ${device.mobileL} {
    height: auto;
    min-height:1000px;
    justify-content: space-evenly;
}
`

const ContainerBloc = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  div {
    width: 21vw;
    @media ${device.mobileL} {
        width: 100%;
    }
    
    :nth-of-type(1) {
      margin-top: 193px;
      @media ${device.laptopL} {
        margin-top: 223px;
      }
    }
    
    :nth-of-type(2) {
      margin-top: 113px;
      @media ${device.laptopL} {
        margin-top: 143px;
      }
    }
    
    :nth-of-type(3) {
      margin-top: 43px;
      @media ${device.laptopL} {
        margin-top: 73px;
      }
    }
    @media ${device.mobileL} {
      :nth-of-type(1), :nth-of-type(2), :nth-of-type(3) {
        margin-top: 10vw;
      }
    }
  }
  
  h2 {
      font-size: 2.6vw;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.03px;
      color: #000000;
  }
  
  p {
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      color: #000000;
  }
  
  @media ${device.mobileL} {
    flex-direction: column;
    
    h2 {
      font-size: 180%;
      line-height: normal;
      margin-bottom: 12px;
    }
  }
`

const ContainerTitle = styled.div`
  display: flex;
  width: 100%;
  h2 {
    margin-top: -1vw;
  }
`

const ImgTitle = styled.div`
  width: 15%;
  height: 37px;
  background: linear-gradient(83deg, #0000ff -19%, #0ccdfe 63%, #66ffe5 127%);
  border-radius: 0 20px 20px 0;
  transform: rotate(-6deg);
  margin-left: -10px;
  margin-right: 20px;
  
  @media ${device.mobileL} {
    width: 14%;
    height: 21px;
    display: none;
  }
`
