import React from 'react'
import {FormattedMessage, useIntl} from 'gatsby-plugin-intl'
import styled from '@emotion/styled'
import {css, keyframes} from '@emotion/core'
import {GlobalContainer} from '../container/GlobalContainer'
import {ButtonGradient} from '../buttons/button'
import ArrowDown from '../../images/angle-arrow-down.inline.svg'
import {device} from '../../devices'

export function scrollToNextSection() {
  return window?.scrollTo(0, window?.innerHeight)
}

export const HeroStandard = () => {
  const intl = useIntl()
  return (
    <GlobalContainer css={css`min-height: 94vh`}>
      <ContainerHero data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease">
        <TitleHero dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'hero_home'})}} />
        <ButtonGradient css={css`margin-top: 23px;`}  onClick={scrollToNextSection}><FormattedMessage id="hero_button_home"/></ButtonGradient>
      </ContainerHero>
      <ContainerArrow>
        <div onClick={scrollToNextSection}>
          <ArrowDown/>
        </div>
      </ContainerArrow>
    </GlobalContainer>
  )
}

const dash = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, 10px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`

const ContainerArrow = styled.div`
  width: 100%;
  display: flex;
  align-item: center;
  justify-content: center;
  height: 10vw;
  position: relative;
  
  @media ${device.mobileL} {
    display: none;
  }
  
  div {
    width: 5vw;
    height: 5vw;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    cursor: pointer;
    display: flex;
    overflow: hidden;
    transition: box-shadow .2s ease;
    animation: ${dash} 2s infinite ease-in-out both;
    display: flex;
    position: absolute;
    top: 5vw;
    align-item: center;
    justify-content: center;
    
    &:hover {
      box-shadow: 0 4px 10px rgba(0,0,0,0.3);  
    }
    
    @media ${device.mobileL} {
      display:none;
    }
  }
  
  svg {
    width: 1.2vw;
    height: auto;
    fill: ${({theme: {primary}}) => primary};
  }
`

const ContainerHero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 6vw;
  height: auto;
  
  @media ${device.mobileL} {
    height: 76vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
  }
`

const TitleHero = styled.h1`
  font-size: 3.711vw;
  text-align: center;
  max-width: 63vw;
  letter-spacing: -0.05px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  
  b {
    color: ${({theme}) => theme.primary}
  }
  
  @media ${device.mobileL} {
    font-size: 10vw;
    max-width: 80vw;
  }
`
